import { Avatar, Col, Row } from "antd";
import Loader from "../components/Loader";
import Utils from "../helpers/Utils";
import Screen, { ScreenState } from './Screen';

interface StaffState extends ScreenState {
    Staff?: any;
}

export default class Staff extends Screen<any, StaffState, any> {

    constructor(props: any) {
        super(props);
        this.Endpoint = "staff";
        this.state = {
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.GetStaff();
    }

    GetStaff = async () => {
        try {
            let staff = await this.queryHelper.Query<any>(this.Endpoint!, { p: "get" });
            this.setState({ Staff: staff.Result });
        } catch (ex) {
        }
    }

    screenRender() {
        return <>
            <Row>
                <Col span={24}>
                    {this.state.Translations && this.state.Staff ?
                        Utils.GenerateBox(this.state.Translations.Staff, "93", <>
                            <Row>
                                <Col span={6}>
                                    <div className="rank-text">{this.state.Translations.Developer} ›</div>
                                    <div className="staff-member">
                                        <Avatar className="avatar" shape="square" size={128} src={"https://popodeus.com/avatars/lucifer.jpg"} />
                                        <div className="staff-name"><a target="_blank" rel="noopener noreferrer" href={"https://74.popmundo.com/World/Popmundo.aspx/Character/1856837"} className="link">Lucifer de Jesús</a></div>
                                    </div>
                                </Col>
                                <Col span={18}>
                                    <div className="rank-text">{this.state.Translations.Moderators} »</div>
                                    {this.state.Staff.Mods.map((m: any) => {
                                        return <div className="staff-member">
                                            <Avatar className="avatar" shape="square" size={128} src={m.avatar} />
                                            <div className="staff-name"><a target="_blank" rel="noopener noreferrer" href={"https://74.popmundo.com/World/Popmundo.aspx/Character/" + m.cid} className="link">{m.name}</a></div>
                                        </div>
                                    })}
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <div className="rank-text">{this.state.Translations.Translators} »</div>
                                    {this.state.Staff.Translators.map((m: any) => {
                                        return <div className="staff-member">
                                            <Avatar className="avatar" shape="square" size={128} src={m.avatar} />
                                            <div className="staff-name"><a target="_blank" rel="noopener noreferrer" href={"https://74.popmundo.com/World/Popmundo.aspx/Character/" + m.cid} className="link">{m.name}</a></div>
                                            <div className="lang-name">({m.lang})</div>
                                        </div>
                                    })}
                                </Col>
                            </Row>


                        </>)
                        : <Loader />}
                </Col>
            </Row>
        </>;
    }


}