import { Spin } from 'antd';
import React from 'react';
import Utils from '../helpers/Utils';

export default class Header extends React.Component<any, any, any> {

    render() {
        return Utils.GenerateBox("Loading", "93",
            <>
                <div style={{ textAlign: "center" }}><Spin size="large" /></div>
            </>)
    }

}
