import { Space } from "antd";
import React from "react";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { AvatarToolSizes } from "../constants/Constants";

interface CropperProps {
    ImageUrl: string;
    ImageType: number;
    SetValues: any;
}

interface CropperState {
    Translations?: any;
    Crop: any;
}

export default class Cropper extends React.Component<CropperProps, CropperState, any> {

    imageRef: any;
    fileUrl?: string;

    constructor(props: CropperProps) {
        super(props);
        let aspectRatio = this.getAspectRatio();
        this.state = {
            Crop: {
                aspect: aspectRatio[0] / aspectRatio[1]
            }
        };
    }

    getAspectRatio = () => {
        return AvatarToolSizes[this.props.ImageType - 1];
    }

    onImageLoaded = (image: any) => {
        this.imageRef = image;
    };

    onCropChange = (crop: any, percentCrop: any) => {
        this.props.SetValues(crop);
        this.setState({ Crop: crop });
    };

    render() {
        return <div className="crop-container">
            <ReactCrop
                src={this.props.ImageUrl}
                crop={this.state.Crop}
                ruleOfThirds
                onImageLoaded={this.onImageLoaded}
                crossorigin="anonymous"
                onChange={this.onCropChange}
            />
            <Space direction="vertical" style={{ display: this.state.Crop.width ? "block" : "none", textAlign: "center" }}>
                <div>Selected Width: {`${Math.round(this.state.Crop.width)} = ${Math.round(this.state.Crop.width / this.getAspectRatio()[0] * 100) / 100} x ${this.getAspectRatio()[0]}`}</div>
                <div>Selected Height: {`${Math.round(this.state.Crop.height)} = ${Math.round(this.state.Crop.height / this.getAspectRatio()[1] * 100) / 100} x ${this.getAspectRatio()[1]}`}</div>
            </Space>
        </div>
    }
}
