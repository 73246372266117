import { Button, Col, Input, message, Row, Space } from "antd";
import Loader from "../components/Loader";
import Utils from "../helpers/Utils";
import Screen, { ScreenState } from './Screen';

interface AddContentState extends ScreenState {
    TranslationName: string;
    TranslationCategory: string;
    TranslationDesc: string;
    TranslationContent: string;
    NewsName: string;
    NewsImageLink: string;
}

export default class AddContent extends Screen<any, AddContentState, any> {

    constructor(props: any) {
        super(props);
        this.Endpoint = "admin";
        this.state = {
            TranslationName: "",
            TranslationCategory: "",
            TranslationDesc: "",
            TranslationContent: "",
            NewsName: "",
            NewsImageLink: ""
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    AddTranslation = async () => {
        let add = await this.queryHelper.Query<any>(this.Endpoint!, {
            p: "addtranslation",
            cat: this.state.TranslationCategory,
            name: this.state.TranslationName,
            about: this.state.TranslationDesc,
            original: this.state.TranslationContent
        });
        if (!add.IsSuccess)
            message.error(add.Result);
        else
            message.success(add.Result);
    }

    AddNews = async () => {
        let add = await this.queryHelper.Query<any>(this.Endpoint!, {
            p: "addnews",
            name: this.state.NewsName,
            imageUrl: this.state.NewsImageLink
        });
        if (!add.IsSuccess)
            message.error(add.Result);
        else
            message.success(add.Result);
    }

    screenRender() {
        return <>
            <Row>
                <Col span={24}>
                    {true ? <>
                        {Utils.GenerateBox("Add Translation", "186", <>
                            <Space direction="vertical" style={{ width: "95%", marginLeft: "15px", marginTop: "15px" }}>
                                <div className="label-input-holder">
                                    <span>Name</span>
                                    <Input value={this.state.TranslationName} size="small" className="text-input"
                                        placeholder="Name"
                                        onChange={(i) => this.setState({ TranslationName: i.target.value })} />
                                </div>
                                <div className="label-input-holder">
                                    <span>Category</span>
                                    <Input value={this.state.TranslationCategory} size="small" className="text-input"
                                        placeholder="Category"
                                        onChange={(i) => this.setState({ TranslationCategory: i.target.value })} />
                                </div>
                                <div className="label-input-holder">
                                    <span>Desc</span>
                                    <Input.TextArea value={this.state.TranslationDesc} size="small" className="text-input"
                                        placeholder="Desc"
                                        onChange={(i) => this.setState({ TranslationDesc: i.target.value })} />
                                </div>
                                <div className="label-input-holder">
                                    <span>Content</span>
                                    <Input.TextArea value={this.state.TranslationContent} size="small" className="text-input"
                                        placeholder="Content"
                                        onChange={(i) => this.setState({ TranslationContent: i.target.value })} />
                                </div>
                                <Button size="small" className="button"
                                    onClick={this.AddTranslation}
                                    disabled={
                                        !this.state.TranslationName ||
                                        !this.state.TranslationCategory ||
                                        !this.state.TranslationContent ||
                                        !this.state.TranslationDesc}>
                                    Save
                                </Button>
                            </Space>
                        </>)}
                        {Utils.GenerateBox("Add News", "186", <>
                            <Space direction="vertical" style={{ width: "95%", marginLeft: "15px", marginTop: "15px" }}>
                                <div className="label-input-holder">
                                    <span>String Name</span>
                                    <Input value={this.state.NewsName} size="small" className="text-input"
                                        placeholder="Name"
                                        onChange={(i) => this.setState({ NewsName: i.target.value })} />
                                </div>
                                <div className="label-input-holder">
                                    <span>Image Link</span>
                                    <Input value={this.state.NewsImageLink} size="small" className="text-input"
                                        placeholder="url"
                                        onChange={(i) => this.setState({ NewsImageLink: i.target.value })} />
                                </div>
                                <Button size="small" className="button"
                                    onClick={this.AddNews}
                                    disabled={!this.state.NewsName}>
                                    Save
                                </Button>
                            </Space>
                        </>)}
                    </> : <Loader />}
                </Col>
            </Row>
        </>;
    }

}