import { Button, Col, Input, Row, Select, Space } from "antd";
import Loader from "../components/Loader";
import Utils from "../helpers/Utils";
import Screen, { ScreenState } from './Screen';

interface NameListState extends ScreenState {
    NameList?: any[];
    Languages?: any[];
    Type: "first" | "last";
    Gender: "Boy" | "Girl";
    Language?: string;
    Search?: string;
    Page: number;
}

export default class NameList extends Screen<any, NameListState, any> {

    constructor(props: any) {
        super(props);
        this.Endpoint = "namelist";
        this.state = {
            Type: "first",
            Gender: "Boy",
            Page: 1
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.GetLanguages();
    }

    GetLanguages = async () => {
        try {
            let languages = await this.queryHelper.Query<any>(this.Endpoint!, { p: "languages" });
            this.setState({ Languages: languages.Result });
        } catch (ex) {
        }
    }


    GetNames = async () => {
        try {
            let namelist = await this.queryHelper.Query<any>(this.Endpoint!, {
                p: "get",
                type: this.state.Type,
                gender: this.state.Gender,
                search: this.state.Search,
                language: this.state.Language,
                page: this.state.Page
            });
            this.setState({ NameList: namelist.Result });
        } catch (ex) {
        }
    }

    renderNameList = () => {
        let cards = this.state.NameList!.map((n, i) => {
            return <Col xs={24} sm={12} md={12} lg={8} xl={6} style={{ whiteSpace: "nowrap" }}>
                <span>{n.Name}</span> <span className="namelist-lang">({n.Language})</span>
            </Col>;
        });
        let rowedArray = [] as JSX.Element[];
        return <Row>{cards}</Row>;
        for (let i = 0; i < cards.length; i += 3)
            rowedArray.push(<Row style={{ marginBottom: "10px" }}>{[cards[i], cards[i + 1], cards[i + 2]]}</Row>);
        return rowedArray;
    }

    screenRender() {
        return <>
            <Row>
                <Col span={24}>
                    {this.state.Translations && this.state.Languages ?
                        Utils.GenerateBox(this.state.Translations.NameLists, "193", <>
                            <Space direction="vertical" style={{ width: "100%", marginBottom: "15px" }} className="no-gap">
                                <Space direction="horizontal" style={{ marginBottom: "10px", width: "100%" }}>

                                    <Select style={{ minWidth: "81px" }} className="p-select" id="type" size="small" defaultValue={"first"} onChange={(i) => this.setState({ Type: i })}>
                                        <Select.Option value={"first"} key={"first"}>{this.state.Translations.FirstNames}</Select.Option>
                                        <Select.Option value={"last"} key={"last"}>{this.state.Translations.LastNames}</Select.Option>
                                    </Select>

                                    <Select style={{ minWidth: "77px" }} className="p-select" id="type" size="small" defaultValue={"Boy"} onChange={(i) => this.setState({ Gender: i })}>
                                        <Select.Option value={"Boy"} key={"Boy"}>{this.state.Translations.Boy}</Select.Option>
                                        <Select.Option value={"Girl"} key={"Girl"}>{this.state.Translations.Girl}</Select.Option>
                                    </Select>

                                </Space>
                                <Select className="p-select" id="type" size="small" style={{ minWidth: "180px", maxWidth: "273px" }}
                                    placeholder={this.state.Translations.SearchAll} allowClear={true}
                                    onChange={(i: any) => this.setState({ Language: i ? i : undefined })}>
                                    {this.state.Languages.map((l: any) => {
                                        return <Select.Option value={l.Name} key={l.Name}>{l.Name}</Select.Option>
                                    })}
                                </Select>
                            </Space>
                            <Row>
                                <Col md={9} sm={24} xs={24}>
                                    <Input style={{ width: "95%" }} value={this.state.Search} size="small" className="text-input" placeholder={this.state.Translations.TypeAWord} onChange={(i) => this.setState({ Search: i.target.value })} />
                                </Col>
                                <Col md={3} sm={24} xs={24}>
                                    <Button size="small" className="button" onClick={this.GetNames}
                                        disabled={!this.state.Language && (!this.state.Search || this.state.Search.length < 3)}
                                    >{this.state.Translations.List}</Button>
                                </Col>
                            </Row>
                            {
                                this.state.NameList ?
                                    <Space direction="vertical" style={{ width: "100%" }}>
                                        <div style={{ marginTop: "15px", paddingLeft: "40px", paddingRight: "40px" }}>
                                            <span onClick={() => this.setState({ Page: this.state.Page - 1 }, this.GetNames)} className="link" style={{ float: "left", display: this.state.Page > 1 ? "block" : "none" }}>{"<< Previous Page"}</span>
                                            <span onClick={() => this.setState({ Page: this.state.Page + 1 }, this.GetNames)} className="link" style={{ float: "right", display: this.state.NameList.length < 51 ? "none" : "block" }} >{"Next Page >>"}</span>
                                        </div>
                                        <div className="namelist">
                                            {this.renderNameList()}
                                        </div>

                                    </Space> : undefined
                            }
                            < p className="tool-stats" > {this.state.Translations.ToolUsed}</p>
                        </>)
                        : <Loader />}
                </Col>
            </Row >
        </>;
    }


}