import { Avatar, Button, Col, Input, message, Row, Space } from "antd";
import Loader from "../components/Loader";
import Utils from "../helpers/Utils";
import Screen, { ScreenState } from './Screen';

interface UserProfileState extends ScreenState {
    ProfileData?: any;
    AvatarUrl: string;
    CharacterName: string;
    OldPassword: string;
    NewPassword: string;
}

export default class UserProfile extends Screen<any, UserProfileState, any> {

    constructor(props: any) {
        super(props);
        this.Endpoint = "userprofile";
        this.state = {
            AvatarUrl: "",
            CharacterName: "",
            OldPassword: "",
            NewPassword: ""
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.GetProfileData();

    }

    GetProfileData = async () => {
        try {
            let data = await this.queryHelper.Query<any>(this.Endpoint!, { p: "get" });
            this.setState({
                ProfileData: data.Result,
                AvatarUrl: data.Result.avatar,
                CharacterName: data.Result.name
            });
        } catch (ex) {

        }
    }

    ValidateCharacterName = async () => {
        if (!this.state.CharacterName)
            return;
        let validate = await this.queryHelper.Query<any>(this.Endpoint!, { p: "validatecharacter", characterId: this.state.ProfileData.cid, characterName: this.state.CharacterName });
        if (!validate.IsSuccess) {
            message.error(this.state.Translations.CNOTFOUND)
            this.setState({ CharacterName: "" });
        } else {
            message.success("Ok")
        }
    }

    UpdateAvatar = async (url: string) => {
        if (!url) url = "";
        let update = await this.queryHelper.Query<any>(this.Endpoint!, { p: "updateavatar", avatar: url });
        if (!update.IsSuccess) {
            message.error("Error");
            this.setState({ AvatarUrl: "" });
        } else {
            message.success("Ok")
        }
    }

    UpdatePassword = async () => {
        if (!this.state.OldPassword || !this.state.NewPassword)
            return;
        let update = await this.queryHelper.Query<any>(this.Endpoint!, {
            p: "updatepassword",
            oldPassword: this.state.OldPassword,
            newPassword: this.state.NewPassword
        });
        if (!update.IsSuccess) {
            message.error(update.Result);
        } else {
            message.success("Ok");
            this.setState({ OldPassword: "", NewPassword: "" });
        }
    }

    screenRender() {
        return <>
            <Row>
                <Col span={24}>
                    {this.state.Translations && this.state.ProfileData ? <>
                        {Utils.GenerateBox(this.state.Translations.ProfileInformation, "90", <>
                            <p dangerouslySetInnerHTML={{ __html: this.state.Translations.UpdateProfileText }} />
                            <Row>
                                <Col xl={16} lg={22} md={24} sm={24} style={{ display: "inline-flex" }}>
                                    <div className="staff-member">
                                        <Avatar className="avatar" shape="square" size={128} src={this.state.AvatarUrl} />
                                        <div className="staff-name">
                                            <a target="_blank" rel="noopener noreferrer"
                                                href={"https://74.popmundo.com/World/Popmundo.aspx/Character/" + this.state.ProfileData.cid}
                                                className="link">{this.state.CharacterName || this.state.ProfileData.name}</a></div>
                                    </div>
                                    <Space direction="vertical" style={{ width: "100%", marginLeft: "15px", marginTop: "15px" }}>
                                        <div className="label-input-holder">
                                            <span>Avatar</span>
                                            <Input title="Avatar" value={this.state.AvatarUrl} size="small" className="text-input"
                                                placeholder={this.state.Translations.Username}
                                                onChange={(i) => this.setState({ AvatarUrl: i.target.value })}
                                                onBlur={(i) => this.UpdateAvatar(i.target.value)} />
                                        </div>
                                        <div className="label-input-holder">
                                            <span>{this.state.Translations.CharacterID}</span>
                                            <Input disabled={true} title={this.state.Translations.CharacterID} value={this.state.ProfileData.cid} size="small" className="text-input" />
                                        </div>
                                        <div className="label-input-holder">
                                            <span>{this.state.Translations.CharacterName}</span>
                                            <Input title={this.state.Translations.CharacterName} value={this.state.CharacterName} size="small" className="text-input"
                                                placeholder={this.state.Translations.CharacterName}
                                                onChange={(i) => this.setState({ CharacterName: i.target.value })}
                                                onBlur={(i) => this.ValidateCharacterName()} />
                                        </div>
                                    </Space>
                                </Col>
                            </Row>
                        </>)}
                        {Utils.GenerateBox(this.state.Translations.AccountSettings, "93", <>
                            <Row>
                                <Col xl={10} lg={16} md={24} sm={24} style={{ display: "inline-flex" }}>
                                    <Space direction="vertical" style={{ width: "100%", marginLeft: "15px", marginTop: "15px" }}>
                                        <div className="label-input-holder">
                                            <span>{this.state.Translations.OldPassword}</span>
                                            <Input.Password title={this.state.Translations.OldPassword} value={this.state.OldPassword} size="small" className="text-input"
                                                placeholder={this.state.Translations.OldPassword}
                                                onChange={(i) => this.setState({ OldPassword: i.target.value })} />
                                        </div>
                                        <div className="label-input-holder">
                                            <span>{this.state.Translations.NewPassword}</span>
                                            <Input.Password title={this.state.Translations.NewPassword} value={this.state.NewPassword} size="small" className="text-input"
                                                placeholder={this.state.Translations.NewPassword}
                                                onChange={(i) => this.setState({ NewPassword: i.target.value })} />
                                        </div>
                                        <Button size="small" className="button"
                                            onClick={this.UpdatePassword}
                                            disabled={!this.state.OldPassword || !this.state.NewPassword}>
                                            {this.state.Translations.Update}
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </>)}
                    </>

                        : <Loader />}
                </Col>
            </Row>
        </>;
    }


}