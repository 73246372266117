import LocalStorageHelper from "./LocalStorageHelper";
import Utils from "./Utils";


export default class QueryHelper {

    apiUrl = "https://popodeus.com/v2/";

    Query = async <T extends unknown>(methodName: string, args: any): Promise<T> => {
        let token = LocalStorageHelper.Get("token");
        let lang = LocalStorageHelper.Get("lang");
        let modifiedArgs = { ...args };
        if (lang)
            modifiedArgs["lang"] = lang;
        else
            modifiedArgs["lang"] = Utils.GetBrowserLanguage();
        let result = (await fetch(this.apiUrl + methodName + ".php", {
            body: JSON.stringify(modifiedArgs),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token ? 'Bearer ' + token : ""
            },
            method: 'POST'
        })).json();
        return result as T;
    }




}