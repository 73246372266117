import Screen, { ScreenState } from './Screen';
import React from "react";
import { Avatar, Col, Row } from 'antd';
import Utils from '../helpers/Utils';
import Loader from '../components/Loader';


interface PopodeusState extends ScreenState {
    Thanks?: any;
}


export default class Popodeus extends Screen<any, PopodeusState, any> {

    constructor(props: any) {
        super(props);
        this.Endpoint = "popodeus"
        this.state = {

        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.GetThanks();
    }

    GetThanks = async () => {
        try {
            let thanks = await this.queryHelper.Query<any>(this.Endpoint!, { p: "thanks" });
            this.setState({ Thanks: thanks.Result });
        } catch (ex) {

        }
    }

    screenRender() {
        return <>
            <Row>
                <Col span={24}>
                    {this.state.Translations && this.state.Thanks ? (<>
                        {Utils.GenerateBox(this.state.Translations.AboutPopodeus, "48", <>
                            <p><div dangerouslySetInnerHTML={{ __html: this.state.Translations.AboutDeus }} /></p>
                        </>)}
                        {Utils.GenerateBox(this.state.Translations.SpecialThanks, "63", <>
                            {this.state.Thanks.map((m: any) => {
                                return <div className="staff-member">
                                    <Avatar className="avatar" shape="square" size={128} src={m.avatar} />
                                    <div className="staff-name"><a target="_blank" rel="noopener noreferrer" href={"https://74.popmundo.com/World/Popmundo.aspx/Character/" + m.cid} className="link">{m.name}</a></div>
                                </div>
                            })}
                        </>)}
                    </>) : <Loader />}
                </Col>
            </Row>
        </>;
    }


}