import { Avatar, Input, message, Select } from "antd";
import React from "react";
import { Button } from 'antd';
import QueryHelper from "../helpers/QueryHelper";
import SharedValues from "../models/SharedValues";
import LocalStorageHelper from "../helpers/LocalStorageHelper";
import { Link, NavigateFunction } from "react-router-dom";
import Utils from "../helpers/Utils";
import Loader from "../components/Loader";
import { AppFolder } from "../constants/Constants";

interface SideMenuProps {
    SetSharedValues: any;
    SharedValues: SharedValues;
    Navigate?: NavigateFunction;
}

interface SideMenuState {
    InputUsername?: string;
    InputPassword?: string;
    Me?: any;
    Translations?: any;
    Languages?: any[];
}

export default class SideMenu extends React.Component<SideMenuProps, SideMenuState, any> {

    queryHelper: QueryHelper;

    constructor(props: SideMenuProps) {
        super(props);
        this.queryHelper = new QueryHelper();
        this.state = {
            InputUsername: "",
            InputPassword: ""
        };
    }

    componentDidMount() {
        this.GetTranslations();
        this.GetLanguages();
        if (this.props.SharedValues.IsLoggedIn)
            this.GetMe();
    }

    componentDidUpdate(prevProps: SideMenuProps) {
        if (prevProps.SharedValues !== this.props.SharedValues)
            this.GetMe();
    }

    GetTranslations = async () => {
        try {
            let res = await this.queryHelper.Query<any>("sidemenu", { p: "translations" });
            this.setState({ Translations: res.Result });
        } catch (ex) {

        }
    }

    GetLanguages = async () => {
        try {
            let languages = await this.queryHelper.Query<any>("register", { p: "languages" });
            this.setState({ Languages: languages.Result });
        } catch (ex) {

        }
    }

    GetMe = async () => {
        try {
            let res = await this.queryHelper.Query<any>("sidemenu", { p: "me" });
            this.setState({ Me: res.Result });
        } catch (ex) {

        }
    }

    SetLanguage = (val: string) => {
        LocalStorageHelper.Save("lang", val);
        window.location.reload();
    }

    Login = async () => {
        try {
            let res = await this.queryHelper.Query<any>("login", {
                username: this.state.InputUsername,
                password: this.state.InputPassword
            });
            if (res.IsSuccess) {
                let shared = {
                    Username: this.state.InputUsername,
                    IsLoggedIn: true,
                    Token: res.Result
                };
                this.props.SetSharedValues(shared);
                LocalStorageHelper.Save("token", res.Result);
                this.GetMe();
                message.success("Ok");
            } else {
                message.error(res.Result);
            }
        } catch (ex) {
            console.error(ex);
        }
    }

    Logout = () => {
        let shared = {
            Username: undefined,
            IsLoggedIn: false,
            Token: undefined
        };
        this.props.SetSharedValues(shared);
        LocalStorageHelper.Remove("token");
    }

    render() {
        return (<div className="side-menu">

            {!this.props.SharedValues.IsLoggedIn && this.state.Translations ?
                Utils.GenerateBox(this.state.Translations.LogIn, "1",
                    <><Input value={this.state.InputUsername} size="small" className="text-input" placeholder={this.state.Translations.Username} onChange={(i) => this.setState({ InputUsername: i.target.value })} />
                        <Input type="password" value={this.state.InputPassword} size="small" className="text-input" placeholder={this.state.Translations.Password} onChange={(i) => this.setState({ InputPassword: i.target.value })} />
                        <Button disabled={!this.state.InputUsername || !this.state.InputPassword} size="small" className="button" onClick={() => this.Login()}>{this.state.Translations.LogIn}</Button>
                        <div><Link to="/register">{this.state.Translations.RegisterLinkText}</Link></div>
                    </>)
                : <></>}

            {this.props.SharedValues.IsLoggedIn && this.state.Translations && this.state.Me ?
                Utils.GenerateBox(this.state.Translations.LogOut, "1",
                    <>
                        <div className="staff-member">
                            <Avatar className="avatar" shape="square" size={128} src={this.state.Me.avatar} />
                            <div className="staff-name"><a target="_blank" rel="noopener noreferrer" href={"https://74.popmundo.com/World/Popmundo.aspx/Character/" + this.state.Me.cid} className="link">{this.state.Me.name}</a></div>
                        </div>
                        <ul className="side-menu-list">
                            <li><Link to={`${AppFolder}/userprofile`}>{this.state.Translations.UserProfile}</Link></li>
                            <li>{this.state.Translations.MyAvatars}</li>
                            {this.state.Me.uname === "Lucifer" && <>
                            <li><Link to={`${AppFolder}/addcontent`}>Add Content</Link></li>

                            </>}
                        </ul>
                        <div style={{ marginTop: "15px" }}><Button size="small" className="button" onClick={this.Logout}>{this.state.Translations.LogOut}</Button></div>
                    </>)
                : <></>}

            {this.state.Translations ?

                Utils.GenerateBox(this.state.Translations.Content, "10",
                    <>
                        <ul className="side-menu-list">
                            <li>{this.state.Translations.Userscripts}</li>
                            <li><Link to={`${AppFolder}/recipes`}>{this.state.Translations.Recipes}</Link></li>
                            <li><Link to={`${AppFolder}/badboys`}>{this.state.Translations.BadBoys}</Link></li>
                            <li><Link to={`${AppFolder}/namelist`}>{this.state.Translations.NameLists}</Link></li>
                            <li><Link to={`${AppFolder}/avatartool`}>{this.state.Translations.ImageResizeUpload}</Link></li>
                            <li><Link to={`${AppFolder}/avator`}>AvaTOR</Link></li>
                            <li><Link to={`${AppFolder}/tourcalculator`}>{this.state.Translations.AdvancedTourCalculator}</Link></li>
                            <li><Link to={`${AppFolder}/health`}>{this.state.Translations.HealthStatistics}</Link></li>
                            <li>{this.state.Translations.Skills}</li>
                            <li>{this.state.Translations.NaughtyLittleSecrets}</li>
                        </ul>
                    </>)
                : <><Loader /></>}

            {this.state.Translations && this.state.Languages ?
                Utils.GenerateBox(this.state.Translations.ChooseLanguage, "31",
                    <>
                        <Select className="p-select" id="language" size="small"
                            placeholder={this.state.Translations.ChooseLanguage}
                            defaultValue={LocalStorageHelper.Get("lang")!}
                            onChange={(i: any) => this.SetLanguage(i)}>
                            {this.state.Languages.map(l => <Select.Option value={l.Name} key={l.Name}>{l.Value}</Select.Option>)}
                        </Select>
                    </>)
                : <Loader />
            }
        </div>);
    }

}