import { Button, Col, Input, Row, Space, Table } from "antd";
import Loader from "../components/Loader";
import Utils from "../helpers/Utils";
import Screen, { ScreenState } from './Screen';

interface UserscriptsState extends ScreenState {
    Scripts?: any[];
    ViewId?: number;
}

export default class Userscripts extends Screen<any, UserscriptsState, any> {

    constructor(props: any) {
        super(props);
        this.Endpoint = "userscripts";
        this.state = {
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.GetScripts();
    }

    GetScripts = async () => {
        try {
            let scripts = await this.queryHelper.Query<any>(this.Endpoint!, { p: "get" });
            console.log(scripts.Result);
            this.setState({ Scripts: scripts.Result });
        } catch (ex) {
        }
    }

    GetTableColumns = () => {
        return [
            {
                title: this.state.Translations.ScriptName,
                dataIndex: 'name',
                key: 'name',
                render: (item: any, row: any) => <span onClick={() => this.setState({ ViewId: row.key })} className="link">{row.name}</span>
            },
            {
                title: this.state.Translations.ScriptVersion,
                dataIndex: 'version',
                key: 'version'
            }
            ,
            {
                title: this.state.Translations.ScriptTotalInstalls,
                dataIndex: 'downloads',
                key: 'downloads'
            }
        ];
    }

    GetTableData = () => {
        return this.state.Scripts!.map(s => {
            return {
                key: s.Id,
                id: s.Id,
                name: s.Name,
                version: s.Version,
                downloads: s.Downloads
            };
        });
    }

    renderVerifyText = (viewedScript: any) => {
        if (!viewedScript.IsVerified)
            return <></>;
        return <span className="script-verified">
            {`${this.state.Translations.ScriptVerified}: ${viewedScript.VerifiedBy}!`}
        </span>;
    }

    renderScreenshots = (viewedScript: any) => {
        if (!viewedScript.Screenshots)
            return <></>;
        return <Space direction="vertical">
            {viewedScript.Screenshots.map((s: string) => <a href={s} target="_blank" ><img className="script-screenshot" src={s} /></a>)}
        </Space>
    }

    screenRender() {
        let viewedScript;
        if (this.state.ViewId)
            viewedScript = this.state.Scripts!.find(s => s.Id === this.state.ViewId);
        return <>
            <Row>
                <Col span={24}>
                    {this.state.Translations && this.state.Scripts ?
                        Utils.GenerateBox(this.state.Translations.Userscripts, "93", <>
                            <p dangerouslySetInnerHTML={{ __html: this.state.Translations.UserscriptsText }} />
                            <Table pagination={false} columns={this.GetTableColumns()} dataSource={this.GetTableData()} />
                        </>)
                        : <Loader />}
                    {this.state.ViewId && <>
                        {Utils.GenerateBox(viewedScript.Name, "", <>
                            <p dangerouslySetInnerHTML={{ __html: viewedScript.InfoText }} />
                            <Space direction="vertical">
                                {this.renderVerifyText(viewedScript)}
                                <Button size="small" className="button">
                                    {this.state.Translations.Download}
                                </Button>
                                {this.renderScreenshots(viewedScript)}
                            </Space>
                        </>)
                        }
                    </>}
                </Col>
            </Row>
        </>;
    }


}