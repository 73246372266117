import { RetweetOutlined } from "@ant-design/icons";
import { Button, Col, Input, message, Row, Select, Space } from "antd";
import Loader from "../components/Loader";
import Utils from "../helpers/Utils";
import Screen, { ScreenState } from './Screen';
const { TextArea } = Input;


interface AvatorState extends ScreenState {
    AvatarList?: string;
    Interval: number;
    AvaTORUrl?: string;
}

export default class Avator extends Screen<any, AvatorState, any> {

    constructor(props: any) {
        super(props);
        this.Endpoint = "avator";
        this.state = {
            Interval: 1
        };
    }

    componentDidMount() {
        super.componentDidMount();

    }

    TOOR = async () => {
        try {
            let avator = await this.queryHelper.Query<any>(this.Endpoint!, {
                p: "TOOR",
                urls: this.state.AvatarList,
                interval: this.state.Interval
            });
            this.setState({ AvaTORUrl: avator.Result });
        } catch (ex) {

        }
    }

    countUrls = () => {
        if (!this.state.AvatarList)
            return 0;
        var count = (this.state.AvatarList.match(/(\r\n|\r|\n)/g) || []).length;
        return count + 1;
    }

    resetScreen = () => {
        this.setState({
            Interval: 1,
            AvaTORUrl: undefined,
            AvatarList: undefined
        })
    }

    screenRender() {
        return <>
            <Row>
                <Col span={24}>
                    {this.state.Translations ?
                        Utils.GenerateBox("AvaTOR", "344", <>
                            <p dangerouslySetInnerHTML={{ __html: this.state.Translations.MainText }} />
                            <p dangerouslySetInnerHTML={{ __html: this.state.Translations.Warning }} />
                            {!this.state.AvaTORUrl ?
                                <Space direction="vertical" style={{ width: "100%", textAlign: "center", marginLeft: "auto", marginRight: "auto" }}>
                                    <TextArea rows={7} value={this.state.AvatarList}
                                        onChange={(e) => this.setState({ AvatarList: e.target.value })}
                                        className="avator-textarea" />
                                    <div>{this.state.Translations.AvatorIntervalText}
                                        <Select style={{ width: "250px", marginLeft: "10px" }} className="p-select" id="interval" size="small" defaultValue={1}
                                            onChange={(i) => this.setState({ Interval: i })}>
                                            <Select.Option selected="selected" value={1}>{this.state.Translations.AvatorInterval1}</Select.Option>
                                            <Select.Option selected="selected" value={2}>{this.state.Translations.AvatorInterval2}</Select.Option>
                                            <Select.Option selected="selected" value={3}>{this.state.Translations.AvatorInterval3}</Select.Option>
                                            <Select.Option selected="selected" value={4}>{this.state.Translations.AvatorInterval4}</Select.Option>
                                            <Select.Option selected="selected" value={5}>{this.state.Translations.AvatorInterval5}</Select.Option>
                                            <Select.Option selected="selected" value={6}>{this.state.Translations.AvatorInterval6}</Select.Option>
                                            <Select.Option selected="selected" value={7}>{this.state.Translations.AvatorInterval7}</Select.Option>
                                            <Select.Option selected="selected" value={8}>{this.state.Translations.AvatorInterval8}</Select.Option>
                                        </Select>
                                    </div>
                                    <Button size="small" className="button" onClick={this.TOOR}
                                        disabled={!this.state.AvatarList || this.countUrls() < 2}>TOOOOOOOOOR!</Button>
                                </Space> :
                                <Space direction="vertical" style={{ width: "100%", textAlign: "center", marginLeft: "auto", marginRight: "auto" }}>
                                    <img className="cropped-image" src={this.state.AvaTORUrl} alt="url" />
                                    <Input style={{ width: "360px", marginRight: "10px" }} id="avator-url" name="avator-url" autoComplete="off" value={this.state.AvaTORUrl}
                                        readOnly={true} size="small" className="text-input" onFocus={(e) => {
                                            e.target.select();
                                            navigator.clipboard.writeText(e.target.value);
                                            message.success("Copied!");
                                        }} />
                                    <span onClick={this.resetScreen} className="start-again">{this.state.Translations.StartAgain} <RetweetOutlined /></span>
                                </Space>}
                            <p className="tool-stats">{this.state.Translations.ToolUsed}</p>
                        </>)
                        : <Loader />}
                </Col>
            </Row>
        </>;
    }


}