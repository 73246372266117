

import React from 'react';
import { Modal } from 'antd';
import '../css/PModal.css';

interface PModalState {

}

interface PModalProps {
    visible: boolean;
    content?: JSX.Element;
    title?: string;
    close: any;
}

export default class PModal extends React.Component<PModalProps, PModalState, any> {

    render() {
        return <>
            <Modal onCancel={this.props.close} title={this.props.title} visible={this.props.visible} footer={
                <span onClick={this.props.close}>Kapatmak için buraya tıklayın ya da escape tuşuna basın</span>
            }>
                {this.props.content}
            </Modal>
        </>;
    }


}
