import { Avatar, Button, Col, message, Row } from "antd";
import Utils from "../helpers/Utils";
import Loader from "../components/Loader";
import Screen, { ScreenState } from './Screen';
import PModal from "../components/PModal";


import tinyClock from '../images/TinyClock.png';
import SharedValues from "../models/SharedValues";
import moment from "moment";

interface HomeProps {
    SharedValues: SharedValues;
}

interface HomeState extends ScreenState {
    LatestNews?: any[];
    LatestMembers?: any[];
    Feedbacks?: any[];
    ModalProps: {
        visible: boolean;
        title?: string;
        content?: JSX.Element;
    },
    NewsPage: number;
    FeedbacksPage: number;
    FeedbackText?: string;
}


export default class Home extends Screen<HomeProps, HomeState, any> {

    constructor(props: HomeProps) {
        super(props);
        this.Endpoint = "home"
        this.state = {
            ModalProps: { visible: false },
            NewsPage: 1,
            FeedbacksPage: 1,
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.GetTranslations();
        this.GetLatestNews();
        this.GetLatestMembers();
        this.GetFeedbacks();
    }

    GetLatestNews = async () => {
        try {
            let news = await this.queryHelper.Query<any>(this.Endpoint!, { p: "latestnews", page: this.state.NewsPage });
            this.setState({ LatestNews: news.Result });
        } catch (ex) {

        }
    }

    GetLatestMembers = async () => {
        try {
            let members = await this.queryHelper.Query<any>(this.Endpoint!, { p: "latestmembers" });
            this.setState({ LatestMembers: members.Result });
        } catch (ex) {

        }
    }

    GetFeedbacks = async () => {
        try {
            let members = await this.queryHelper.Query<any>(this.Endpoint!, { p: "feedbacks", page: this.state.FeedbacksPage });
            this.setState({ Feedbacks: members.Result });
        } catch (ex) {

        }
    }

    showModal = (r: any) => {
        this.setState({
            ModalProps: {
                visible: true,
                content: <div dangerouslySetInnerHTML={{ __html: r.content }} />,
                title: r.name
            }
        })
    }


    getFeedbackBGClass = (f: any) => {
        let bgClass = "tbc tbcw ";
        if (f.rank > 1) {
            bgClass += "admin-feedback";
            return bgClass;
        }
        if (f.username === this.props.SharedValues.Username) {
            bgClass += "own-feedback";
            return bgClass;
        }
        return bgClass;
    }

    sendFeedback = async () => {
        try {
            let feedback = await this.queryHelper.Query<any>("home", { p: "sendfeedback", content: this.state.FeedbackText });
            if (feedback.IsSuccess) {
                message.success(feedback.Result);
                this.setState({ FeedbackText: "" });
            } else {
                message.error(feedback.Result);
            }
        } catch (ex) {
        }
    }

    screenRender() {
        return <>
            <Row>
                <Col md={12} xs={24} sm={24}>
                    {this.state.Translations ?
                        Utils.GenerateBox(this.state.Translations.WelcomeTitle, "85", <>{Utils.GenerateJSX(this.state.Translations.WelcomeText)}</>)
                        : <Loader />}
                </Col>
                <Col md={12} xs={24} sm={24}>
                    {this.state.LatestNews && this.state.Translations ?
                        Utils.GenerateBox(this.state.Translations.LatestNewsTitle, "97", <div className="latest-news">{
                            this.state.LatestNews.map(r => {
                                return <div className="news-entry">
                                    <span>{moment(r.date).format("DD.MM.YYYY")}</span> <h4 className="link" onClick={() => this.showModal(r)}>{r.name}</h4>
                                </div>
                            })
                        }
                            <div>
                                <span onClick={() => this.setState({ NewsPage: this.state.NewsPage + 1 }, this.GetLatestNews)} className="link" style={{ float: "left", display: this.state.LatestNews.length < 5 ? "none" : "block" }}>{"<< Previous Page"}</span>
                                <span onClick={() => this.setState({ NewsPage: this.state.NewsPage - 1 }, this.GetLatestNews)} className="link" style={{ float: "right", display: this.state.NewsPage > 1 ? "block" : "none" }} >{"Next Page >>"}</span>
                            </div>
                        </div>)
                        : <Loader />}
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    {this.state.Translations && this.state.LatestMembers ?
                        Utils.GenerateBox(this.state.Translations.LatestMembersTitle, "135", <div className="latest-members"><Row>{
                            this.state.LatestMembers.map(m => {
                                return <Col xs={12} sm={8} md={8} lg={8} xl={4}>
                                    <div className="home-member">
                                        <Avatar className="avatar" shape="square" size={128} src={m.image} />
                                        <a target="_blank" rel="noopener noreferrer" href={"https://74.popmundo.com/World/Popmundo.aspx/Character/" + m.cid} className="link">{m.name}</a>
                                    </div>
                                </Col>
                            })
                        }
                        </Row></div>)
                        : <Loader />}
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    {this.state.Translations && this.state.Feedbacks ?
                        Utils.GenerateBox(this.state.Translations.Feedback, "135", <div className="feedbacks">
                            <div className="feedback-text">{this.state.Translations.FeedbackText}</div>
                            <div onClick={() => this.setState({ FeedbacksPage: this.state.FeedbacksPage + 1 }, this.GetFeedbacks)} className="link" style={{ marginBottom: "15px", float: "left", display: this.state.Feedbacks.length < 8 ? "none" : "block" }}>{"<< Previous Page"}</div>
                            <div onClick={() => this.setState({ FeedbacksPage: this.state.FeedbacksPage - 1 }, this.GetFeedbacks)} className="link" style={{ marginBottom: "15px", float: "right", display: this.state.FeedbacksPage > 1 ? "block" : "none" }} >{"Next Page >>"}</div>
                            <div>
                                {
                                    this.state.Feedbacks.map(f => {
                                        return <div className="tb">
                                            <div className={this.getFeedbackBGClass(f)}>
                                                {f.content}
                                            </div>
                                            <div className="tbfblr">
                                                <div className="tbblr tbslw">
                                                    <span>
                                                        {f.name && f.cid ? <a target="_blank" rel="noopener noreferrer" href={"https://74.popmundo.com/World/Popmundo.aspx/Character/" + f.cid} className="link">{f.name}</a>
                                                            : <span>{this.state.Translations.Guest}</span>}
                                                        <img src={tinyClock} className="servertime" alt="date" /> {f.date}
                                                        <i> ({f.lang})</i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                            <div style={{ textAlign: 'right' }}>
                                <textarea name="content" className="feedback-area" maxLength={150} value={this.state.FeedbackText} onChange={(e) => this.setState({ FeedbackText: e.target.value })}></textarea>
                                <span style={{ color: (this.state.FeedbackText || "").length > 149 ? "#8b0303" : "black", float: 'left', marginLeft: "15px", marginTop: "-10px" }}>{(this.state.FeedbackText || "").length}/150</span>
                                <Button disabled={(this.state.FeedbackText || "").length < 4} onClick={this.sendFeedback} size="small" className="button">{this.state.Translations.SendFeedback}</Button>
                            </div>
                        </div>)
                        : <Loader />}
                </Col>
            </Row>
            <PModal {...this.state.ModalProps} close={() => this.setState({ ModalProps: { visible: false } })} />
        </>;
    }


}