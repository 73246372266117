import React from 'react';
import './css/App.css';
import './css/Common.css';
import './css/Achievements.css';

import { Col, Row } from 'antd';
import Header from './sections/Header';
import Home from './screens/Home';
import SideMenu from './sections/SideMenu';
import QueryHelper from './helpers/QueryHelper';
import SharedValues from './models/SharedValues';
import LocalStorageHelper from './helpers/LocalStorageHelper';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Staff from './screens/Staff';
import Popmundo from './screens/Popmundo';
import Popodeus from './screens/Popodeus';
import Lucifer from './screens/Lucifer';
import Register from './screens/Register';
import Recipes from './screens/Recipes';
import BadBoys from './screens/BadBoys';
import NameList from './screens/NameList';
import AvatarTool from './screens/AvatarTool';
import AvaTOR from './screens/AvaTOR';
import TourCalculator from './screens/TourCalculator';
import { AppFolder } from './constants/Constants';
import HealthStats from './screens/HealthStats';
import { ConfigProvider } from 'antd';
import es_ES from 'antd/es/locale/es_ES';
import tr_TR from 'antd/es/locale/tr_TR';
import en_US from 'antd/es/locale/en_US';
import Utils from './helpers/Utils';
import UserProfile from './screens/UserProfile';
import AddContent from './screens/AddContent';
import Userscripts from './screens/Userscripts';


interface AppState {
  SharedValues: SharedValues;
}

export default class App extends React.Component<any, AppState, any> {

  queryHelper: QueryHelper;

  constructor(props: any) {
    super(props);
    this.queryHelper = new QueryHelper();
    this.state = {
      SharedValues: {}
    };
  }

  componentDidMount() {
    this.CheckSession();
  }

  SetLanguage = () => {
    let lang = LocalStorageHelper.Get("lang");
    if (lang)
      return;
  }

  CheckSession = async () => {
    try {
      let res = await this.queryHelper.Query<any>("session", undefined);
      if (res.IsSuccess) {
        let shared = { ...this.state.SharedValues };
        shared.Username = res.Result;
        shared.IsLoggedIn = true;
        this.setState({ SharedValues: shared });
      } else {
        LocalStorageHelper.Remove("token");
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  SetSharedValues = (values: SharedValues) => {
    let shared = { ...this.state.SharedValues, ...values };
    this.setState({ SharedValues: shared });
  }

  GetAntLanguage = () => {
    let lang = LocalStorageHelper.Get("lang");
    if (!lang)
      lang = Utils.GetBrowserLanguage();
    switch (lang) {
      case "tr":
        return tr_TR;
      case "en":
        return en_US;
      case "es":
        return es_ES;
      default:
        return en_US;
    }
  }

  render() {
    return (<>
      <Router>
        <ConfigProvider locale={this.GetAntLanguage()}>
          <div className="main-container">
            <Header />
            <div className="main-content">
              <Row >
                <Col md={18} sm={24} xs={24}>
                  <Routes  >
                    <Route path={`${AppFolder}/`} element={<Home SharedValues={this.state.SharedValues} />} />
                    <Route path={`${AppFolder}/home`} element={<Home SharedValues={this.state.SharedValues} />} />
                    <Route path={`${AppFolder}/staff`} element={<Staff SharedValues={this.state.SharedValues} />} />
                    <Route path={`${AppFolder}/popmundo`} element={<Popmundo SharedValues={this.state.SharedValues} />} />
                    <Route path={`${AppFolder}/popodeus`} element={<Popodeus SharedValues={this.state.SharedValues} />} />
                    <Route path={`${AppFolder}/lucifer`} element={<Lucifer SharedValues={this.state.SharedValues} />} />
                    <Route path={`${AppFolder}/register`} element={<Register SharedValues={this.state.SharedValues} />} />
                    <Route path={`${AppFolder}/recipes`} element={<Recipes SharedValues={this.state.SharedValues} />} />
                    <Route path={`${AppFolder}/badboys`} element={<BadBoys SharedValues={this.state.SharedValues} />} />
                    <Route path={`${AppFolder}/namelist`} element={<NameList SharedValues={this.state.SharedValues} />} />
                    <Route path={`${AppFolder}/avatartool`} element={<AvatarTool SharedValues={this.state.SharedValues} />} />
                    <Route path={`${AppFolder}/avator`} element={<AvaTOR SharedValues={this.state.SharedValues} />} />
                    <Route path={`${AppFolder}/tourcalculator`} element={<TourCalculator SharedValues={this.state.SharedValues} />} />
                    <Route path={`${AppFolder}/health`} element={<HealthStats SharedValues={this.state.SharedValues} />} />
                    <Route path={`${AppFolder}/userprofile`} element={<UserProfile SharedValues={this.state.SharedValues} />} />
                    <Route path={`${AppFolder}/addcontent`} element={<AddContent SharedValues={this.state.SharedValues} />} />
                    <Route path={`${AppFolder}/scripts`} element={<Userscripts SharedValues={this.state.SharedValues} />} />
                  </Routes>
                </Col>
                <Col lg={6} sm={24} xs={24}>
                  <SideMenu SetSharedValues={this.SetSharedValues} SharedValues={this.state.SharedValues} />
                </Col>
              </Row>
              <div className="footer">
                <div>
                  © Copyright 2014-2021&nbsp;
                  <a href="http://74.popmundo.com/World/Popmundo.aspx/Character/1856837" rel="noreferrer" target="_blank">Lucifer de Jesús</a>
                </div>
              </div>
            </div>
          </div>
          <br></br>
        </ConfigProvider>
      </Router>
    </>
    );
  }
}
