import { Col, Input, Row, Space } from "antd";
import Loader from "../components/Loader";
import Utils from "../helpers/Utils";
import Screen, { ScreenState } from './Screen';

interface BadBoysState extends ScreenState {
    BadBoys?: any;
    SearchInput?: string;
}

export default class BadBoys extends Screen<any, BadBoysState, any> {

    constructor(props: any) {
        super(props);
        this.Endpoint = "badboys";
        this.state = {
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.GetBadBoys();
    }

    GetBadBoys = async () => {
        try {
            let badboys = await this.queryHelper.Query<any>(this.Endpoint!, { p: "get" });
            this.setState({ BadBoys: badboys.Result });
        } catch (ex) {
        }
    }

    GetFilteredBadBoys = () => {
        if (!this.state.SearchInput)
            return this.state.BadBoys;
        return this.state.BadBoys.filter((b: any) => b.Name.includes(this.state.SearchInput) || b.Cid.includes(this.state.SearchInput));
    }

    screenRender() {
        return <>
            <Row>
                <Col span={24}>
                    {this.state.Translations && this.state.BadBoys ?
                        Utils.GenerateBox(this.state.Translations.BadBoys, "186", <>
                            <p dangerouslySetInnerHTML={{ __html: this.state.Translations.BadBoysText }} />
                            <p>{this.state.Translations.BadBoysText2}</p>
                            <div style={{ width: "100%", textAlign: "center" }}>
                                <Input style={{ width: "200px" }} id="search" name="search" autoComplete="off" value={this.state.SearchInput}
                                    size="small" className="text-input" placeholder={this.state.Translations.BadBoysSearch} allowClear={true}
                                    onChange={(i) => this.setState({ SearchInput: i.target.value })} />
                            </div>
                            <Space className="badboys-list" direction="vertical">
                                {this.GetFilteredBadBoys().map((b: any) => {
                                    return <a target="_blank" rel="noreferrer" className="badboy-entry" href={"http://74.popmundo.com/World/Popmundo.aspx/Character/" + b.Cid}>
                                        {b.Name} - {b.Cid}
                                    </a>
                                })}

                            </Space>
                        </>)
                        : <Loader />}
                </Col>
            </Row>
        </>;
    }


}