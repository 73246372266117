import { Button, Checkbox, Col, Input, message, Row, Select, Space } from "antd";
import Loader from "../components/Loader";
import { AvatarToolSizes } from "../constants/Constants";
import Utils from "../helpers/Utils";
import Cropper from "../sections/Cropper";
import Screen, { ScreenState } from './Screen';
import { RetweetOutlined } from '@ant-design/icons';
enum ScreenMode {
    Entry,
    Crop,
    Done
}

interface AvatarToolState extends ScreenState {
    ImageUrl?: string;
    ImageType: number;
    ScreenMode: ScreenMode;
    WatermarkEnabled: boolean;
    WatermarkText?: string;
    CropValues?: any;
    Cropping: boolean;
}

export default class AvatarTool extends Screen<any, AvatarToolState, any> {

    constructor(props: any) {
        super(props);
        this.Endpoint = "avatartool";
        this.state = {
            ScreenMode: ScreenMode.Entry,
            ImageType: 1,
            WatermarkEnabled: false,
            Cropping: false
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    cropRequest = async () => {
        this.setState({ Cropping: true })
        try {
            let url = await this.queryHelper.Query<any>(this.Endpoint!, {
                p: "crop",
                src: this.state.ImageUrl,
                wantedWidth: AvatarToolSizes[this.state.ImageType - 1][0],
                wantedHeight: AvatarToolSizes[this.state.ImageType - 1][1],
                width: Math.round(this.state.CropValues.width),
                height: Math.round(this.state.CropValues.height),
                x: Math.round(this.state.CropValues.x),
                y: Math.round(this.state.CropValues.y),
                watermarkText: this.state.WatermarkEnabled && this.state.WatermarkText ? this.state.WatermarkText : undefined
            });
            this.setState({ ImageUrl: url.Result, ScreenMode: ScreenMode.Done });
        } catch (ex) {
            console.log("error", ex);
        }
    }

    setValues = (values: any) => {
        this.setState({ CropValues: values });
    }

    resetScreen = () => {
        this.setState({
            Cropping: false,
            ImageType: 1,
            ScreenMode: ScreenMode.Entry,
            WatermarkEnabled: false,
            ImageUrl: undefined,
            CropValues: undefined,
            WatermarkText: undefined
        });
    }

    renderEntry = () => {
        return <div style={{ display: "inline-flat", textAlign: "center" }}>
            <Input style={{ width: "360px", marginRight: "10px" }} id="url" name="url" autoComplete="off" value={this.state.ImageUrl}
                size="small" className="text-input" placeholder={this.state.Translations.ImageUrlPlaceHolder} allowClear={true}
                onChange={(i) => this.setState({ ImageUrl: i.target.value })} />
            <Select style={{ width: "250px", marginRight: "10px" }} className="p-select" id="type" size="small" defaultValue={1} onChange={(i) => this.setState({ ImageType: i })}>
                <Select.Option selected="selected" value={1}>114x156 ({this.state.Translations.CharacterAvatar})</Select.Option>
                <Select.Option value={2}>489x66 ({this.state.Translations.ArtistLogo})</Select.Option>
                <Select.Option value={3}>489x258 ({this.state.Translations.LocaleImage})</Select.Option>
                <Select.Option value={4}>190x190 ({this.state.Translations.AlbumCover})</Select.Option>
            </Select>
            <Button size="small" className="button" onClick={() => this.setState({ ScreenMode: ScreenMode.Crop })}
                disabled={!this.state.ImageUrl}
            >{this.state.Translations.OpenAvatar}</Button>
        </div>;
    }

    renderCrop = () => {
        return <>
            <Cropper ImageUrl={this.state.ImageUrl!} ImageType={this.state.ImageType!} SetValues={this.setValues} />
            <div style={{ width: "100%", textAlign: "center" }}>
                <Input disabled={!this.state.WatermarkEnabled} style={{ width: "200px", marginRight: "10px" }} id="url" name="url" autoComplete="off" value={this.state.WatermarkText}
                    size="small" className="text-input" placeholder={this.state.Translations.WatermarkPlaceholder} allowClear={true}
                    onChange={(i) => this.setState({ WatermarkText: i.target.value })} />
                <Checkbox onChange={(c) => this.setState({ WatermarkEnabled: c.target.checked })}>{this.state.Translations.WatermarkLabel}</Checkbox>
            </div>
            <Button size="small" className="button" onClick={this.cropRequest}
                disabled={this.state.Cropping || !this.state.CropValues || !this.state.CropValues.width || !this.state.CropValues.height}>Chop chop!</Button>
        </>;
    }

    renderDone = () => {
        return <>
            <Space direction="vertical">
                <img className="cropped-image" src={this.state.ImageUrl} alt="url" />
                <Input style={{ width: "360px", marginRight: "10px" }} id="cropped-url" name="cropped-url" autoComplete="off" value={this.state.ImageUrl}
                    readOnly={true} size="small" className="text-input" onFocus={(e) => {
                        e.target.select();
                        navigator.clipboard.writeText(e.target.value);
                        message.success("Copied!");
                    }} />
                <span onClick={this.resetScreen} className="start-again">{this.state.Translations.StartAgain}  <RetweetOutlined /> </span>
            </Space>
        </>;
    }

    renderScreen = () => {
        switch (this.state.ScreenMode) {
            case ScreenMode.Entry:
                return this.renderEntry();
            case ScreenMode.Crop:
                return this.renderCrop();
            case ScreenMode.Done:
                return this.renderDone();
        }
    }

    screenRender() {
        return <>
            <Row>
                <Col span={24}>
                    {this.state.Translations ?
                        Utils.GenerateBox(this.state.Translations.MainTitle, "187", <>
                            <Space direction="vertical" style={{ width: "100%", textAlign: "center" }}>
                                {this.state.Translations.MainText}
                                {this.renderScreen()}
                                <p className="tool-stats">{this.state.Translations.ToolUsed}</p>
                            </Space>
                        </>)
                        : <Loader />}
                </Col>
            </Row>
        </>;
    }


}