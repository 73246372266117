import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Row, Col } from "antd";
import React from "react";

interface CityPickerProps {
    CityList?: any[];
    onChange: any;
    Translations?: any;
}

interface CityPickerState {
    PickedCities: any[];
}

export default class CityPicker extends React.Component<CityPickerProps, CityPickerState, any> {

    constructor(props: CityPickerProps) {
        super(props);
        this.state = {
            PickedCities: []
        };
    }

    AddToPicked = (c: any) => {
        let picked = [...this.state.PickedCities];
        picked.push(c);
        this.setState({ PickedCities: picked });
        this.props.onChange(picked);
    }

    RemoveFromPicked = (index: number) => {
        let arr = [...this.state.PickedCities];
        arr.splice(index, 1);
        this.setState({ PickedCities: arr });
        this.props.onChange(arr);
    }

    render() {
        if (!this.props.CityList || !this.props.Translations)
            return <></>;
        let leftCityCols = this.props.CityList
            .filter(c => !this.state.PickedCities.includes(c))
            .sort((a: string, b: string) => a.localeCompare(b))
            .map((c, i) => {
                return <Col key={"CL" + i} span={8}><span style={{ cursor: "pointer" }} onClick={() => this.AddToPicked(c)}>{c} <PlusOutlined key={"L" + i} /></span></Col>
            });
        let leftRows = [] as any[];
        for (let index = 0; index < leftCityCols.length; index += 3) {
            leftRows.push(<Row key={"L" + index}>{[leftCityCols[index], leftCityCols[index + 1], leftCityCols[index + 2]]}</Row>);
        }

        let rightCityCols = this.state.PickedCities.map((c, i) => {
            return <Col key={"CR" + i} span={8}><span style={{ cursor: "pointer" }} onClick={() => this.RemoveFromPicked(i)}>{`${i + 1}.`} {c} <MinusOutlined key={"R" + i} /></span></Col>
        });

        let rightRows = [] as any[];
        for (let index = 0; index < rightCityCols.length; index += 3) {
            rightRows.push(<Row key={"R" + index}>{[rightCityCols[index], rightCityCols[index + 1], rightCityCols[index + 2]]}</Row>);
        }
        return <Row>
            <Col span={11}>
                <div className="available-cities">
                    {leftRows}
                </div>
            </Col >
            <Col span={12}>
                <span style={{ marginLeft: "15px", fontStyle: "italic" }}>{this.props.Translations.PickedCitiesColText}:</span>
                <div className="picked-cities">
                    {rightRows}
                </div>
            </Col>
        </Row >;
    }

}