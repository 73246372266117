export default class LocalStorageHelper {

    static Save = (key: string, value: any) => {
        window.localStorage.setItem(key, value);
    }

    static Get = (key: string) => {
        return window.localStorage.getItem(key);
    }

    static Remove = (key: string) => {
        window.localStorage.removeItem(key);
    }
}