import Screen, { ScreenState } from './Screen';
import { Button, Col, Input, Row, Select, Card } from 'antd';
import Utils from '../helpers/Utils';
import Loader from '../components/Loader';


interface RecipesState extends ScreenState {
    Name?: string;
    Category?: string;
    RecipesResult?: any[];
}


export default class Recipes extends Screen<any, RecipesState, any> {

    constructor(props: any) {
        super(props);
        this.Endpoint = "recipes"
        this.state = {

        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    ListRecipes = async () => {
        try {
            let recipes = await this.queryHelper.Query<any>(this.Endpoint!, { p: "search", cat: this.state.Category, name: this.state.Name });
            this.setState({ RecipesResult: recipes.Result });
        } catch (ex) {
        }
    }

    renderRecipeCards = () => {
        let cards = this.state.RecipesResult!.map((r, i) => {
            return <Col span={8}>
                <Card title={r.Name} className="recipe-card">
                    <p dangerouslySetInnerHTML={{ __html: r.Text }} />
                </Card>
            </Col>;
        });
        let rowedArray = [] as JSX.Element[];
        for (let i = 0; i < cards.length; i += 3)
            rowedArray.push(<Row style={{ marginBottom: "10px" }}>{[cards[i], cards[i + 1], cards[i + 2]]}</Row>);
        return rowedArray;
    }

    screenRender() {
        return <>
            <Row>
                <Col span={24}>
                    {this.state.Translations ? (<>
                        {Utils.GenerateBox(this.state.Translations.Recipes, "194", <>
                            <div dangerouslySetInnerHTML={{ __html: this.state.Translations.RecipesText }} />
                            <Row>
                                <Col xxl={6} xl={8} lg={10} sm={24}>
                                    <Input placeholder={this.state.Translations.Optional}
                                        size="small" className="text-input"
                                        style={{ maxWidth: "250px" }}
                                        value={this.state.Name}
                                        onChange={(e) => this.setState({ Name: e.target.value })} />
                                </Col>
                                <Col xxl={6} xl={8} lg={10} sm={24}>
                                    <Select className="p-select" id="categories" size="small"
                                        style={{ maxWidth: "250px" }}
                                        placeholder={this.state.Translations.SelectCategory}
                                        allowClear={true}
                                        onChange={(i) => this.setState({ Category: i ? i.toString() : undefined })}>
                                        <Select.Option value={"cooking"} key={"cooking"}>{this.state.Translations.BasicCooking}</Select.Option>
                                        <Select.Option value={"cigar"} key={"cigar"}>{this.state.Translations.CigarMaking}</Select.Option>
                                        <Select.Option value={"distillation"} key={"distillation"}>{this.state.Translations.Distillation}</Select.Option>
                                        <Select.Option value={"agriculture"} key={"agriculture"}>{this.state.Translations.Agriculture}</Select.Option>
                                        <Select.Option value={"baking"} key={"baking"}>{this.state.Translations.Baking}</Select.Option>
                                        <Select.Option value={"shisha tobacco"} key={"shisha tobacco"}>{this.state.Translations.TobaccoChemistry}</Select.Option>
                                        <Select.Option value={"cheesemaking"} key={"cheesemaking"}>{this.state.Translations.Cheesemaking}</Select.Option>
                                        <Select.Option value={"chemistry"} key={"chemistry"}>{this.state.Translations.Chemistry}</Select.Option>
                                        <Select.Option value={"sewing"} key={"sewing"}>{this.state.Translations.BasicSewing}</Select.Option>
                                        <Select.Option value={"hatmaking"} key={"hatmaking"}>{this.state.Translations.HatMaking}</Select.Option>
                                        <Select.Option value={"mixology"} key={"mixology"}>{this.state.Translations.Mixology}</Select.Option>
                                    </Select>
                                </Col>
                                <Col lg={4} sm={24}>
                                    <Button size="small" className="button" onClick={this.ListRecipes}>{this.state.Translations.List}</Button>
                                </Col>
                            </Row>
                            <div dangerouslySetInnerHTML={{ __html: this.state.Translations.RecipeWarning }} />
                        </>)}
                        {this.state.RecipesResult ?
                            Utils.GenerateBox(this.state.Translations.RecipeFound.replace("{n}", this.state.RecipesResult.length), "", <>
                                {this.renderRecipeCards()}
                            </>)
                            : undefined}






                    </>) : <Loader />}
                </Col>
            </Row>
        </>;
    }


}