export default class Utils {

    static GenerateBox = (title: string, achievementId: string, content: JSX.Element) => {
        return <div className="box">
            <div className="box-icon">
                <div title={title} className={achievementId ? `Achievement Achievement_${achievementId}` : ``}></div>
            </div>
            <h3 className={achievementId ? "" : "no-icon"}>{title}</h3>
            {content}
        </div>
    }

    static GenerateJSX = (html: string) => {
        return <div dangerouslySetInnerHTML={{ __html: html }} />;
    }

    static GetBrowserLanguage = () => {
        return navigator.language.substr(0, 2);
    }
}