import Screen, { ScreenState } from './Screen';
import React from "react";
import { Avatar, Col, Row } from 'antd';
import Utils from '../helpers/Utils';
import Loader from '../components/Loader';

interface LuciferState extends ScreenState {
}

export default class Lucifer extends Screen<any, LuciferState, any> {

    constructor(props: any) {
        super(props);
        this.Endpoint = "lucifer";
        this.state = {

        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    screenRender() {
        return <>
            <Row>
                <Col span={24}>
                    {this.state.Translations ? (<>
                        {Utils.GenerateBox(this.state.Translations.AboutLuciferTitle, "48", <>
                            <div className="staff-member">
                                <Avatar className="avatar" shape="square" size={128} src={"https://popodeus.com/avatars/lucifer.jpg"} />
                            </div>
                            <p><div dangerouslySetInnerHTML={{ __html: this.state.Translations.AboutLucifer }} /></p>
                            <strong>Popmundo: </strong> <a target="_blank" rel="noopener noreferrer" href="http://74.popmundo.com/World/Popmundo.aspx/Character/1856837">Lucifer de Jesús</a><br />
                            <strong>E-Mail: </strong> Lucifer@popodeus.com<br />
                            <strong>Discord: </strong> Lu#6660
                        </>)}
                    </>) : <Loader />}
                </Col>
            </Row>
        </>;
    }


}