import Screen, { ScreenState } from './Screen';
import React from "react";
import {  Button, Col, Input, message, Row, Select } from 'antd';
import Utils from '../helpers/Utils';
import Loader from '../components/Loader';


interface RegisterState extends ScreenState {
    InputUsername?: string;
    InputEmail?: string;
    InputPassword?: string;
    InputPassword2?: string;
    CharacterId?: string;
    CharacterName?: string;
    CharacterValidated: boolean;
    Gender: string;
    Language?: string;
    Languages?: any[];
    RegisterDisabled: boolean;

}


export default class Register extends Screen<any, RegisterState, any> {

    constructor(props: any) {
        super(props);
        this.Endpoint = "register";
        this.state = {
            InputUsername: "",
            InputPassword: "",
            InputPassword2: "",
            CharacterId: "",
            CharacterName: "",
            Gender: "",
            Language: "en",
            RegisterDisabled: false,
            CharacterValidated: false
        };
    }

    componentDidMount() {
        this.GetTranslations();
        this.GetLanguages()
    }

    GetLanguages = async () => {
        try {
            let languages = await this.queryHelper.Query<any>(this.Endpoint!, { p: "languages" });
            this.setState({ Languages: languages.Result });
        } catch (ex) {

        }
    }


    validatePassword = (i: React.FocusEvent<HTMLInputElement, Element>) => {
        if (i.target.value !== this.state.InputPassword) {
            message.error("Not Ok");
            this.setState({ InputPassword2: "" });
            i.target.style.borderColor = '#a10000';
        } else {
            i.target.style.borderColor = '';
        }
    }

    validateCharacter = async () => {
        if (!this.state.CharacterId || !this.state.CharacterName)
            return;
        let validate = await this.queryHelper.Query<any>(this.Endpoint!, { p: "validatecharacter", characterId: this.state.CharacterId, characterName: this.state.CharacterName });
        if (!validate.IsSuccess) {
            message.error(this.state.Translations.CNOTFOUND)
            this.setState({ CharacterName: "" });
        } else {
            message.success("Ok")
            this.setState({ CharacterValidated: true, Gender: validate.Result });
        }
    }

    register = async () => {
        this.setState({ RegisterDisabled: true })
        let reg = await this.queryHelper.Query<any>(this.Endpoint!,
            {
                p: "register",
                characterId: this.state.CharacterId,
                characterName: this.state.CharacterName,
                username: this.state.InputUsername,
                password: this.state.InputPassword,
                email: this.state.InputEmail,
                gender: this.state.Gender,
                language: this.state.Language
            });
        if (!reg.IsSuccess) {
            message.error(reg.Result);
            this.setState({ RegisterDisabled: false })
        } else {
            message.success(reg.Result);
            this.setState({ RegisterDisabled: false })
            // setTimeout(() => window.location.href = "/", 5000);
        }
    }

    resetCharacter = () => {
        this.setState({
            CharacterValidated: false,
            CharacterId: undefined,
            CharacterName: undefined
        });
    }


    screenRender() {
        return <>
            <Row>
                <Col span={24}>
                    {this.state.Translations && this.state.Languages ? (<>
                        {Utils.GenerateBox(this.state.Translations.Register, "48", <>
                            <div style={{ marginBottom: "10px" }}>{this.state.Translations.RegistrantGreeting} {this.state.Translations.ActivationText}</div>
                            <Row>
                                <Col span={9}>
                                    <Input value={this.state.InputUsername} size="small" className="text-input" placeholder={this.state.Translations.Username} onChange={(i) => this.setState({ InputUsername: i.target.value })} />
                                </Col>
                                <Col offset={1} span={9}>
                                    <Input id="registerEmail" name="registerEmail" autoComplete="off" value={this.state.InputEmail} size="small" className="text-input" placeholder={this.state.Translations.EMail} onChange={(i) => this.setState({ InputEmail: i.target.value })} />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={9}>
                                    <Input id="registerPassword" name="registerPassword" autoComplete="off" type="password" value={this.state.InputPassword} size="small" className="text-input" placeholder={this.state.Translations.Password} onChange={(i) => this.setState({ InputPassword: i.target.value })} />
                                    <span style={{ marginLeft: "5px" }} className="italic-info">({this.state.Translations.NeverPassword})</span>
                                </Col>
                                <Col offset={1} span={9}>
                                    <Input id="registerPassword2" name="registerPassword2" autoComplete="off" type="password" value={this.state.InputPassword2} size="small" className="text-input" placeholder={this.state.Translations.Password + " !!"} onChange={(i) => this.setState({ InputPassword2: i.target.value })} onBlur={(i) => this.validatePassword(i)} />
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "15px" }}>
                                <Col span={9}>
                                    <Select className="p-select" id="language" size="small" placeholder={this.state.Translations.ChooseLanguage} onChange={(i: any) => this.setState({ Language: i })}>
                                        {this.state.Languages.map(l => <Select.Option value={l.Name} key={l.Name}>{l.Value}</Select.Option>)}
                                    </Select>
                                </Col>
                            </Row>

                            <Row style={{ marginTop: "15px" }}>
                                <Col span={9}>
                                    <Input disabled={this.state.CharacterValidated} style={{ borderColor: this.state.CharacterValidated ? "#00a108" : "" }} value={this.state.CharacterId} size="small" className="text-input" placeholder={'# ' + this.state.Translations.CharacterID} onChange={(i) => this.setState({ CharacterId: i.target.value })} onBlur={(i) => this.validateCharacter()} />
                                </Col>
                                <Col offset={1} span={9}>
                                    <Input disabled={this.state.CharacterValidated} style={{ borderColor: this.state.CharacterValidated ? "#00a108" : "" }} value={this.state.CharacterName} size="small" className="text-input" placeholder={this.state.Translations.CharacterName} onChange={(i) => this.setState({ CharacterName: i.target.value })} onBlur={(i) => this.validateCharacter()} />

                                </Col>
                                <Col span={2} flex={"auto"}>
                                    <img alt="Ok" style={{ display: this.state.CharacterValidated ? undefined : "none", cursor: "pointer", marginLeft: "5px", verticalAlign: "middle" }} src="https://popodeus.com/images/TinyX_White.png" onClick={this.resetCharacter} />
                                </Col>
                            </Row>
                            <div style={{ marginTop: "15px" }}>
                                <Button
                                    disabled={
                                        !this.state.CharacterValidated ||
                                        !this.state.CharacterId ||
                                        !this.state.CharacterName ||
                                        !this.state.Gender ||
                                        !this.state.InputEmail ||
                                        !this.state.InputPassword ||
                                        !this.state.InputPassword2 ||
                                        !this.state.Language ||
                                        !this.state.InputUsername ||
                                        this.state.RegisterDisabled
                                    }
                                    size="small" className="button" onClick={this.register}>Hit the Road Jack!</Button>
                            </div>
                        </>)}
                    </>) : <Loader />}
                </Col>
            </Row>
        </>;
    }


}