import { Col, Row, Table, TablePaginationConfig } from "antd";
import Loader from "../components/Loader";
import Utils from "../helpers/Utils";
import Screen, { ScreenState } from './Screen';

interface HealthStatsState extends ScreenState {
    Stats?: any;
    PageSize: number;
    CurrentPage: number;
}

export default class HealthStats extends Screen<any, HealthStatsState, any> {


    constructor(props: any) {
        super(props);
        this.Endpoint = "health";
        this.state = {
            PageSize: 8,
            CurrentPage: 1
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.GetStats();
    }

    GetStats = async () => {
        try {
            let stats = await this.queryHelper.Query<any>(this.Endpoint!, { p: "get" });
            this.setState({ Stats: stats.Result });
        } catch (ex) {
        }
    }

    GetColumns = () => {
        return [
            {
                title: this.state.Translations.City,
                dataIndex: 'City',
                key: 'City',
                sorter: (a: any, b: any) => a.City.localeCompare(b.City, 'en'),
            },
            {
                title: this.state.Translations.Chlamydia,
                dataIndex: 'Chlamydia',
                key: 'Chlamydia',
                sorter: (a: any, b: any) => a.Chlamydia - b.Chlamydia,
            },
            {
                title: this.state.Translations.Flu,
                dataIndex: 'Flu',
                key: 'Flu',
                sorter: (a: any, b: any) => a.Flu - b.Flu,
            },
            {
                title: this.state.Translations.ChickenPox,
                dataIndex: 'ChickenPox',
                key: 'ChickenPox',
                sorter: (a: any, b: any) => a.ChickenPox - b.ChickenPox,
            },
            {
                title: this.state.Translations.ZombieVirus,
                dataIndex: 'ZombieVirus',
                key: 'ZombieVirus',
                sorter: (a: any, b: any) => a.ZombieVirus - b.ZombieVirus,
            },
            {
                title: this.state.Translations.Rabies,
                dataIndex: 'Rabies',
                key: 'Rabies',
                sorter: (a: any, b: any) => a.Rabies - b.Rabies,
            },
            {
                title: this.state.Translations.Scabies,
                dataIndex: 'Scabies',
                key: 'Scabies',
                sorter: (a: any, b: any) => a.Scabies - b.Scabies,
            },
            {
                title: this.state.Translations.Total,
                dataIndex: 'Total',
                key: 'Total',
                sorter: (a: any, b: any) => a.Total - b.Total,
            },
            {
                title: this.state.Translations.LastUpdate + " (UTC)",
                dataIndex: 'LastUpdate',
                key: 'LastUpdate',
            }
        ];
    }

    GetGridData = () => {
        let stats = this.state.Stats;
        return stats.map((s: any, index: number) => {
            let total = parseInt(s.Chlamydia)
                + parseInt(s.Flu)
                + parseInt(s.Chlamydia)
                + parseInt(s.ChickenPox)
                + parseInt(s.ZombieVirus)
                + parseInt(s.Rabies)
                + parseInt(s.Scabies);
            return {
                key: "stat" + index,
                City: s.City,
                Chlamydia: s.Chlamydia,
                Flu: s.Flu,
                ChickenPox: s.ChickenPox,
                ZombieVirus: s.ZombieVirus,
                Rabies: s.Rabies,
                Scabies: s.Scabies,
                Total: total,
                LastUpdate: s.LastUpdate
            };
        });
    }

    tableChange = (e: TablePaginationConfig) => {
        console.log(e);
        this.setState({ PageSize: e.pageSize || 8, CurrentPage: e.current || 1 });
    }

    screenRender() {
        let pagination: TablePaginationConfig = {
            current: this.state.CurrentPage,
            pageSize: this.state.PageSize,
            showSizeChanger: true,
            pageSizeOptions: ["8", "15", "49"],
            hideOnSinglePage: true,

        };
        return <>
            <Row>
                <Col span={24}>
                    {this.state.Translations && this.state.Stats ?
                        Utils.GenerateBox(this.state.Translations.HealthStatistics, "72", <>
                            <p dangerouslySetInnerHTML={{ __html: this.state.Translations.HealthStatisticsText }} />
                            <Table className="health-table" dataSource={this.GetGridData()}
                                columns={this.GetColumns()}
                                style={{ borderRadius: "10px" }}
                                pagination={pagination}
                                onChange={(e) => this.tableChange(e)}
                                showSorterTooltip={true} />
                            <p className="tool-stats">{this.state.Translations.ToolUsed}</p>
                        </>)
                        : <Loader />}
                </Col>
            </Row>
        </>
    }


}