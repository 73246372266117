import moment, { Moment } from "moment";

export class DateHelper {

    static OFFSET_FIRSTDOY = moment('10.11.2021', 'DD.MM.YYYY'); // 1
    static OFFSET_BB = 8;
    static OFFSET_PALOOZA = 12;
    static OFFSET_KRAKEN = 21;
    static OFFSET_DOTD = 27;
    static OFFSET_HALLOWEEN = 47;

    static GetNameIfSpecialDay = (date: Moment) => {
        let diff = date.diff(DateHelper.OFFSET_FIRSTDOY, 'days'); // result is 1
        let offset = diff % 56;
        switch (offset) {
            case 0:
                return "First Day Of Year";
            case this.OFFSET_BB:
                return "Big Bang";
            case this.OFFSET_PALOOZA:
                return "Popopalooza";
            case this.OFFSET_KRAKEN:
                return "Kraken";
            case this.OFFSET_DOTD:
                return "DotD";
            case this.OFFSET_HALLOWEEN:
                return "Trick or Threat";
            default:
                return "";
        }
    }



}