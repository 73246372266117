import Screen, { ScreenState } from './Screen';
import React from "react";
import { Col, Row } from 'antd';
import Utils from '../helpers/Utils';
import Loader from '../components/Loader';


interface PopmundoState extends ScreenState {
}


export default class Popmundo extends Screen<any, PopmundoState, any> {

    constructor(props: any) {
        super(props);
        this.Endpoint = "popmundo";
        this.state = {

        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    screenRender() {
        return <>
            <Row>
                <Col span={24}>
                    {this.state.Translations ? (<>
                        <h1 style={{ paddingLeft: "10px" }}><div dangerouslySetInnerHTML={{ __html: this.state.Translations.WhatIsPopmundoTitle }} /></h1>
                        <p style={{ paddingLeft: "10px" }}><div dangerouslySetInnerHTML={{ __html: this.state.Translations.WhatIsPopmundo }} /></p>

                        {Utils.GenerateBox(this.state.Translations.WhatDoINeedTitle, "", <>
                            <p><div dangerouslySetInnerHTML={{ __html: this.state.Translations.WhatDoINeed }} /></p>
                        </>)}

                        {Utils.GenerateBox(this.state.Translations.DoesPopmundoCostTitle, "", <>
                            <p><div dangerouslySetInnerHTML={{ __html: this.state.Translations.DoesPopmundoCost }} /></p>
                        </>)}


                        {Utils.GenerateBox(this.state.Translations.BullshitTitle, "", <>
                            <p><div dangerouslySetInnerHTML={{ __html: this.state.Translations.Bullshit }} /></p>
                        </>)}

                        {Utils.GenerateBox(this.state.Translations.RegisteringTitle, "", <>
                            <p><div dangerouslySetInnerHTML={{ __html: this.state.Translations.Registering }} /></p>
                        </>)}

                        {Utils.GenerateBox(this.state.Translations.HowOldTitle, "", <>
                            <p><div dangerouslySetInnerHTML={{ __html: this.state.Translations.HowOld }} /></p>
                        </>)}

                        {Utils.GenerateBox(this.state.Translations.PrivacyTitle, "", <>
                            <p><div dangerouslySetInnerHTML={{ __html: this.state.Translations.Privacy }} /></p>
                        </>)}


                        <p className="italic-info"><div dangerouslySetInnerHTML={{ __html: this.state.Translations.Quote }} /></p>
                    </>) : <Loader />}
                </Col>
            </Row>
        </>;
    }


}