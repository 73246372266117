
import React from "react";
import QueryHelper from "../helpers/QueryHelper";

export interface ScreenState {
    Translations?: any;

}


export default abstract class Screen<P, S extends ScreenState, SS> extends React.Component<P, S, SS> {

    abstract screenRender(): JSX.Element;
    queryHelper: QueryHelper;
    public Endpoint?: string;

    constructor(props: P) {
        super(props);
        this.queryHelper = new QueryHelper();
    }

    componentDidMount() {
        this.GetTranslations();
    }

    GetTranslations = async () => {
        try {
            let translations = await this.queryHelper.Query<any>(this.Endpoint!, { p: "translations" });
            this.setState({ Translations: translations.Result });
        } catch (ex) {

        }
    }

    render() {
        return <div className="screen">
            {this.screenRender()}
        </div >;
    }


}