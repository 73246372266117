
import { Col, Row, Menu, Button, Space } from 'antd';
import React from 'react';

import logo from '../images/logo.png';
import QueryHelper from '../helpers/QueryHelper';
import { Link } from 'react-router-dom';
import {
    AppstoreOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    PieChartOutlined,
    DesktopOutlined,
    ContainerOutlined,
    MailOutlined,
} from '@ant-design/icons';

interface HeaderState {
    Translations?: any;
    MenuCollapsed: boolean;
}

export default class Header extends React.Component<any, HeaderState, any> {

    queryHelper: QueryHelper;

    constructor(props: any) {
        super(props);
        this.queryHelper = new QueryHelper();
        this.state = {
            MenuCollapsed: true
        };
    }


    componentDidMount() {
        this.GetTranslations();
    }

    GetTranslations = async () => {
        try {
            let translations = await this.queryHelper.Query<any>("header", { p: "translations" });
            this.setState({ Translations: translations.Result });
        } catch (ex) {
        }
    }

    render() {
        return (<div className="header">
            <Row style={{}}>
                <Col className="centered-col header-logo-row" span={24}>
                    <div style={{ textAlign: "center" }}><img className="logo" src={logo} alt="Popodeus" /></div>
                </Col>
            </Row>
            <Row className="tool-row">
                <Col span={24} className="right-col">
                    <div style={{ paddingRight: "20px", color: 'white', fontStyle: 'italic', marginTop: "-25px" }}>
                        Unchain your mind!
                    </div>
                </Col>
            </Row>
            <Row className="top-menu">
                <Col span={24} className="centered-col">
                    <div className="top-menu-nav">
                        {this.state.Translations && this.state.Translations !== null ? <ul>
                            <li><Link className="black" to="/">{this.state.Translations.Home}</Link></li>
                            <li><Link className="black" to="/staff">{this.state.Translations.Staff}</Link></li>
                            <li><Link className="black" to="/popmundo">{this.state.Translations.AboutPopmundo}</Link></li>
                            <li><Link className="black" to="/popodeus">{this.state.Translations.AboutPopodeus}</Link></li>
                            <li><Link className="black" to="/lucifer">{this.state.Translations.AboutLucifer}</Link></li>
                        </ul> : <></>}
                    </div>
                </Col>
            </Row>
        </div>);
    }


}