import Screen, { ScreenState } from './Screen';
import { Button, Col, Input, Row, Space, DatePicker, Checkbox, Select, Switch, CheckboxOptionType, message, Tooltip } from 'antd';
import { Moment } from 'moment';
import Utils from '../helpers/Utils';
import Loader from '../components/Loader';
import moment from 'moment';
import CityPicker from '../sections/CityPicker';
import { CopyOutlined } from '@ant-design/icons';
import { DateHelper } from '../helpers/DateHelper';


enum ScreenMode {
    Entry,
    Pick,
    Done
}

interface TourCalculatorState extends ScreenState {
    CityList?: any[];
    ScreenMode: ScreenMode;
    StartDate?: Moment;
    DayCount?: number;
    ChoosenDays?: number[];
    StartingCity?: number;
    DoubleShow: boolean;
    CustomCities: boolean;
    ShowTime?: string;
    ExtraShowTime?: string;
    CustomCityList?: string[];
    CalculatedShows?: any[];
}

export default class TourCalculator extends Screen<any, TourCalculatorState, any> {

    constructor(props: any) {
        super(props);
        this.Endpoint = "tourcalculator"
        this.state = {
            ScreenMode: ScreenMode.Entry,
            StartDate: moment(),
            DoubleShow: true,
            ShowTime: "12:00",
            ExtraShowTime: "22:00",
            CustomCities: false
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.GetCities();
    }

    GoToPick = () => {
        let days = this.getDays();
        this.setState({ ScreenMode: ScreenMode.Pick, ChoosenDays: days });
    }

    GetCities = async () => {
        try {
            let cityList = await this.queryHelper.Query<any>(this.Endpoint!, { p: "cities" });
            this.setState({ CityList: cityList.Result });
        } catch (ex) {
        }
    }

    getDays = () => {
        let days: number[] = [];
        for (let i = 0; i < this.state.DayCount!; i++)
            days.push(i);
        return days;
    }

    generateCityOrder = () => {
        let point = this.state.StartingCity!;
        let cities = [];
        for (let index = point; index < this.state.CityList!.length; index++) {
            cities.push(this.state.CityList![index]);
        }
        for (let index = 0; index < point; index++) {
            cities.push(this.state.CityList![index]);
        }
        return cities;
    }


    getDateByDay = (day: number) => {
        return moment(this.state.StartDate!).add(day, 'd');
    }

    calculateTour = () => {
        let cities: any[];
        if (this.state.CustomCities) {
            cities = [...this.state.CustomCityList!];
        } else {
            cities = this.generateCityOrder();
        }
        let shows = [] as any[];
        if (this.state.DoubleShow) {
            shows.push({
                Date: this.getDateByDay(this.state.ChoosenDays![0]).format('DD.MM.YYYY'),
                Hour: this.state.ExtraShowTime,
                City: cities[0]
            });
        }
        this.state.ChoosenDays!.forEach((d, i) => {
            if (i === 0)
                return;
            let date = this.getDateByDay(d);
            let cityIndex = (i - 1) % cities.length;
            shows.push({
                Date: date.format('DD.MM.YYYY'),
                Hour: this.state.ShowTime,
                City: cities[cityIndex]
            });
            if (!this.state.DoubleShow)
                return;
            if (i === this.state.ChoosenDays!.length - 1)
                return;
            cityIndex = (cityIndex + 1) % cities.length;
            shows.push({
                Date: date.format('DD.MM.YYYY'),
                Hour: this.state.ExtraShowTime,
                City: cities[cityIndex]
            });
        })
        this.setState({ CalculatedShows: shows, ScreenMode: ScreenMode.Done });
    }

    setCities = (cities: any[]) => {
        this.setState({ CustomCityList: cities });
    }

    renderDone = () => {
        let allText = "";
        let shows = this.state.CalculatedShows!.map(s => {
            let text = `${s.Date} ${s.Hour} ${s.City}`;
            allText += text + '\n';
            return <span>{text}</span>;
        });

        return <Space direction="vertical" className="calculated-shows">
            <div style={{ marginBottom: "15px" }}>{this.state.Translations.ThisIsYourPlan}</div>
            <Tooltip title={"Copy"}><CopyOutlined style={{ fontSize: "20px", color: "#096dd9" }} onClick={() => {
                navigator.clipboard.writeText(allText);
                message.success("Copied!");
            }} /></Tooltip>
            {shows}
        </Space>
    }

    renderPick = () => {
        let days = this.getDays();
        let options: CheckboxOptionType[] = days.map((d, i) => {
            let date = this.getDateByDay(d);
            let specialDay = DateHelper.GetNameIfSpecialDay(date);
            return {
                value: d,
                label: date.format('DD.MM.YYYY') + (specialDay ? " - " + specialDay : ""),
                key: d,
                readOnly: i === 0,
                disabled: i === 0
            };
        });

        return <>
            <Space direction="vertical" style={{ textAlign: 'left', width: '100%' }}>
                <div>
                    <span style={{ marginRight: "10px" }}>{this.state.Translations.OrderOfCities}</span>
                    <Switch title={this.state.Translations.OrderOfCities} checked={this.state.CustomCities} onChange={(e) => this.setState({ CustomCities: e })} />
                </div>

                {!this.state.CustomCities &&
                    <Select className="p-select" id="type" size="small" style={{ width: "220px" }}
                        placeholder={this.state.Translations.StartingCityPlaceholder} allowClear={true}
                        onChange={i => this.setState({ StartingCity: i ? parseInt(i.toString()) : undefined })}>
                        {this.state.CityList!.map((c: any, i: number) => {
                            return <Select.Option value={i} key={i}>{c}</Select.Option>
                        })}
                    </Select>}
                {this.state.CustomCities && <CityPicker Translations={this.state.Translations} CityList={this.state.CityList} onChange={this.setCities} />}
                <Space direction="horizontal">
                    <Switch checkedChildren={this.state.Translations.DoubleShow} checked={this.state.DoubleShow} onChange={(e) => this.setState({ DoubleShow: e })} />
                    <Select style={{ width: "100px", marginRight: "10px" }} className="p-select" id="type" size="small" defaultValue={"12:00"}
                        onChange={(i) => this.setState({ ShowTime: i })}>
                        <Select.Option selected="selected" value={"12:00"}>12:00</Select.Option>
                        <Select.Option value={"14:00"}>14:00</Select.Option>
                        <Select.Option value={"16:00"}>16:00</Select.Option>
                        <Select.Option value={"18:00"}>18:00</Select.Option>
                        <Select.Option value={"20:00"}>20:00</Select.Option>
                        <Select.Option value={"22:00"}>22:00</Select.Option>
                    </Select>
                    {this.state.DoubleShow &&
                        <Select style={{ width: "100px", marginRight: "10px" }} className="p-select" id="type" size="small" defaultValue={"22:00"}
                            onChange={(i) => this.setState({ ExtraShowTime: i })}>
                            <Select.Option selected="selected" value={"12:00"}>12:00</Select.Option>
                            <Select.Option value={"14:00"}>14:00</Select.Option>
                            <Select.Option value={"16:00"}>16:00</Select.Option>
                            <Select.Option value={"18:00"}>18:00</Select.Option>
                            <Select.Option value={"20:00"}>20:00</Select.Option>
                            <Select.Option value={"22:00"}>22:00</Select.Option>
                        </Select>
                    }
                </Space>
                <div dangerouslySetInnerHTML={{ __html: this.state.Translations.Step2Text }} />
                <Checkbox.Group className="tour-days" options={options} value={this.state.ChoosenDays}
                    onChange={e => this.setState({ ChoosenDays: e as any })} />
                <Button
                    disabled={
                        !this.state.ChoosenDays ||
                        (!this.state.CustomCities && !this.state.StartingCity) ||
                        (this.state.CustomCities && (!this.state.CustomCityList || this.state.CustomCityList.length < 2)) ||
                        !this.state.ShowTime ||
                        (this.state.DoubleShow && !this.state.ExtraShowTime)
                    }
                    size="small" className="button" onClick={this.calculateTour}>{this.state.Translations.Calculate}</Button>
            </Space>
        </>;
    }

    renderEntry = () => {
        return <>
            <div dangerouslySetInnerHTML={{ __html: this.state.Translations.MainText }} />
            <Space direction="horizontal" style={{ marginTop: "10px" }}>
                <DatePicker style={{ minWidth: "150px" }} placeholder={this.state.Translations.DatePickerPlaceholder}
                    format={'DD/MM/YYYY'} size="small" className="p-datepicker"
                    defaultValue={moment()}
                    onChange={(e) => this.setState({ StartDate: e! })}
                    disabledDate={(e) => e.isBefore(moment())} />
                <Input style={{ width: "150px" }} id="search" name="search" autoComplete="off" value={this.state.DayCount}
                    size="small" className="text-input" placeholder={"1-200"} allowClear={true} maxLength={3} type={"number"}
                    onChange={(i) => this.setState({ DayCount: i.target.value ? parseInt(i.target.value) : 1 })} />
                <Button disabled={!this.state.DayCount || !this.state.StartDate} size="small" className="button" onClick={this.GoToPick}>{this.state.Translations.NextStep}</Button>
            </Space></>;
    }

    screenRender() {
        return <>
            <Row>
                <Col span={24}>
                    {this.state.Translations && this.state.CityList ? (<>
                        {Utils.GenerateBox(this.state.Translations.MainTitle, "59", <>
                            {this.state.ScreenMode === ScreenMode.Entry ? this.renderEntry() : undefined}
                            {this.state.ScreenMode === ScreenMode.Pick ? this.renderPick() : undefined}
                            {this.state.ScreenMode === ScreenMode.Done ? this.renderDone() : undefined}
                            <p className="tool-stats">{this.state.Translations.ToolUsed}</p>
                        </>)}
                    </>) : <Loader />}
                </Col>
            </Row>
        </>;
    }


}